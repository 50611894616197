<template>
  <div class="qytx__footer">
    <div class="qytx__foote-icon">
      <img src="@/assets/images/logo.png" alt="" />
    </div>
    <div class="qytx__foote-right">
      <div class="qytx-footer__links">
        <div class="qytx-footer__link-group">
          <!-- <a href="" target="_self">广告</a>
          <span class="qytx-footer__links-point"></span>
          <a href="" target="_self">认证</a>
          <span class="qytx-footer__links-point"></span> -->
          <span @click="toDetail(1)">关于我们</span>
          <span class="qytx-footer__links-point"></span>
          <span @click="toDetail(2)">侵权投诉</span>
          <span class="qytx-footer__links-point"></span>
          <span @click="toDetail(3)">隐私政策</span>
          <span class="qytx-footer__links-point"></span>
          <span @click="toDetail(4)" style="margin-right:12px">服务协议</span>
        </div>
        <div class="qytx-footer__link-group">
          <span>© 2021 113手游网 版权所有</span>
        </div>
      </div>
      <div class="qytx-footer__company">
        <div class="qytx-footer__link-group">
          <a
            class="qytx-footer__link-item"
            href="https://beian.miit.gov.cn"
            style="margin-right:12px"
          >
            粤ICP备2021077590号
          </a>
          <!-- <a class="qytx-footer__link-item" href="">
            粤网文(2019)3544-255号
          </a> -->
          <!-- <a class="qytx-footer__link-item" href="">
            经营许可证编号: 沪B2-20170322
          </a> -->
          <a class="qytx-footer__link-item" href="" style="margin-right:12px">
            113手游网
          </a>
          <a class="qytx-footer__link-item" href="">
            公司地址:广州市天河区天河软件园建工路11号
          </a>
          <a class="qytx-footer__link-item" href="">
            注册地址:广州市天河区天河软件园建工路11号
          </a>
        </div>
      </div>
      <div class="qytx-footer__icon-links">
        <a
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602009337"
          target="_self"
          class="qytx-footer__icon-link"
        >
          <span
            class="sprites-icon qytx-footer__icon-links-img sprites-icon-shanghai_record"
          ></span>
          <div>粤公网安备 44010602009337号</div>
        </a>
        <a
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602009337"
          target="_self"
          class="qytx-footer__icon-link"
          title="广东省互联网违法和不良信息举报中心"
        >
          <span
            class="sprites-icon qytx-footer__icon-links-img  sprites-icon-report_phone"
          ></span>
          <div>违法信息举报电话：020-87185235</div>
        </a>
        <a
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602009337"
          target="_self"
          class="qytx-footer__icon-link"
          title="网上有害信息举报专区"
        >
          <span
            class="sprites-icon qytx-footer__icon-links-img sprites-icon-harmful_info_report"
          ></span>
          <div>网上有害信息举报专区</div>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
// 侧边底部公司信息组件
export default {
  name: "QytxFooterBottom",
  data() {
    return {};
  },
  methods: {
    toDetail(type) {
      this.$openRouter({
        name: "content_page.html", // 跳转地址
        query: { type: type },
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
