<template>
  <div class="qytx__hot-game-side">
    <el-card class="qytx__hot-game-card">
      <div slot="header" class="clearfix">
        <span>热门游戏</span>
      </div>
      <a class="sidebar-app-list__more" href="/hot.html">
        <svg-icon icon-class="plus-icon"></svg-icon>
      </a>
      <div class="sidebar-app-list__list">
        <div
          class="sidebar-app-list__item"
          v-for="item in hotGameList"
          :key="item.id"
          @click="toGameDetail(item.id)"
        >
          <div class="app-item__img">
            <img v-lazy="imgBaseUrl + item.icon" alt="" />
          </div>

          <div class="app-item__infos">
            <div class="tap-app-title__wrap">
              <p class="qytx-app-title__name overflow1">{{ item.name }}</p>
              <small
                class="qytx-app-title__tag"
                v-for="cate in item.category_name.slice(0, 1)"
                :key="cate.id"
                >{{ cate.name }}</small
              >
            </div>

            <div class="app-item__tags">
              <div
                class="app-item__tag"
                v-for="tags in item.class_name"
                :key="tags.id"
              >
                {{ tags.name }}
              </div>
            </div>
          </div>
          <div class="app-item__rate">
            <svg-icon icon-class="score-icon"></svg-icon>
            <div class="app-item__rate-value">{{ item.score }}</div>
          </div>
        </div>
      </div>
      <!-- <div class="sidebar-app-list__footer">
        <div class="sidebar-app-list__show-more">更多</div>
      </div> -->
    </el-card>
  </div>
</template>
<script>
import { hotGameData } from "@/api/common";
export default {
  name: "HotGameSide",
  data() {
    return {
      imgBaseUrl: process.env.VUE_APP_IMG_BASE_URL, //图片根地址
      hotGameList: [],
    };
  },
  created() {
    this.getHotGameData();
  },
  methods: {
    async getHotGameData() {
      let param = {
        str: "hot",
        pageSize: "6",
      };
      let res = await hotGameData(param);
      if (res.code == 200) {
        this.$set(this, "hotGameList", res.data);
      }
    },
    toGameDetail(id) {
      this.$openRouter({
        name: "game_detail.html", // 跳转地址
        query: { id: id },
      });
    },
  },
};
</script>

<style lang="less">
@import "./index.less";
</style>
