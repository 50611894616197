import Vue from "vue";
import Router from "vue-router";
// 资讯
const News = (resolve) => {
  require.ensure(["../views/index.vue"], () => {
    resolve(require("../views/index.vue"));
  });
};
// 资讯详情
const NewsDetail = (resolve) => {
  require.ensure(["../views/detail.vue"], () => {
    resolve(require("../views/detail.vue"));
  });
};

Vue.use(Router);

let base = `${process.env.BASE_URL}`;

export default new Router({
  mode: "history",
  base: base,
  routes: [
    {
      path: "/news",
      name: "News",
      component: News,
      meta: {
        title: "资讯",
      },
    },
    {
      path: "/newsdetail",
      name: "NewsDetail",
      component: NewsDetail,
      meta: {
        title: "资讯详情",
      },
    },
  ],
});
