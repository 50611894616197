/*
 * @Author: TQF
 * @Date: 2020-05-04 21:27:02
 * @LastEditTime: 2020-05-08
 * @LastEditors: TQF
 * @Description:
 */
import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import router from '@/router'

// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL
    // withCredentials: true, // send cookies when cross-domain requests
})
// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent
        // if (store.getters.token) {   
        //   // let each request carry token
        //   // ['X-Token'] is a custom headers key
        //   // please modify it according to the actual situation
        //   config.headers['token'] = store.getters.token || getToken()
        // }
        // if (getToken()) {
        //     config.headers['token'] = getToken()
        // }
        // config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
        return config
    },
    error => {
        // do something with request error
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        //网络请求成功
        if (response.status == 200) {
            const res = response.data
            // if the custom code is not 20000, it is judged as an error.
            // TOKEN超时处理
            if (res.code === 505) {
                Message.closeAll()
                if (window.resetToken) {
                    clearTimeout(window.resetToken)
                }
                Message({
                    message: res.msg || '',
                    type: 'error',
                    duration: 1 * 1000,
                    customClass: "qytx__toast-error",
                    onClose: function () {
                        window.resetToken = setTimeout(() => {
                            store.dispatch('user/resetToken').then(() => {
                                router.replace('/login')
                            })
                        }, 100)
                    }
                })
                return Promise.reject(res)
            } else {
                return res
            }
        } else {
            //网络请求报错
            switch (response.status) {
                case 404:
                    Message({
                        message: '找不到资源',
                        type: 'error',
                        duration: 5 * 1000,
                        customClass: "qytx__toast-error",
                    })
                    break;
                case 500:
                    Message({
                        message: '服务器内部错误',
                        type: 'error',
                        duration: 5 * 1000,
                        customClass: "qytx__toast-error",
                    })
                    break;
                default:
                    Message({
                        message: response.statusText,
                        type: 'error',
                        duration: 5 * 1000,
                        customClass: "qytx__toast-error",
                    })
                    break;
            }
        }
    },
    error => {
        Message({
            message: '服务器内部错误',
            type: 'error',
            duration: 5 * 1000,
            customClass: "qytx__toast-error",
        })
        return Promise.reject(error)
    }
)

export default service
