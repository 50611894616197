// 引入axios
import request from '@/utils/request'

// 首页数据
export function gameData(params) {
    return request({
        url: '/v1/game/list',
        method: 'get',
        params: params
    })
}
//游戏详情
export function gameInfo(params) {
    return request({
        url: '/v1/game/info',
        method: 'get',
        params: params
    })
}
//游戏更新日志
export function gameUpadteLog(params) {
    return request({
        url: '/v1/game/upadtelog',
        method: 'get',
        params: params
    })
}
