<template>
  <div class="qytx__main-header qytx-sticky">
    <div class="qytx__main-header-content" v-if="false">
      <div class="navbar-search__wrap  top-operation__search-small">
        <el-input
          placeholder="请输入搜索关键字"
          v-model="keyword"
          clearable
          @change="searchClick()"
        >
          <svg-icon
            @click="searchClick()"
            slot="prefix"
            class="qytx__search-svg"
            icon-class="search"
          ></svg-icon>
        </el-input>
      </div>
    </div>
    <div class="qytx__top-operation-wrap" v-if="false">
      <div class="top-operation__avatar">
        <img class="user-avatar" src="@/assets/images/user@avatar.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "QytxMainHeader",
  props: {
    searchType: {
      type: Number,
      default: 0, //0 默认综合
    },
    searchKeyword: {
      type: String,
      default: "",
    },
  },
  components: {},
  data() {
    return {
      keyword: this.searchKeyword,
    };
  },
  created() {},
  methods: {
    searchClick() {
      if (this.keyword.length) {
        this.$router.push({
          path: "/search",
          query: {
            keyword: this.keyword,
            searchType: this.searchType,
          },
        });
      }
    },
  },
};
</script>

<style lang="less">
@import "./index.less";
</style>
