<template>
  <div v-show="isShow" class="backToTop" ref="backToTop" @click="backTop">
    <i class="iconfont icon-jiantou"></i>
  </div>
</template>

<script>
export default {
  name: "BackToTop",
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    // 回到顶部方法
    backTop() {
      // 获取文档高度
      const html = document.documentElement;
      let timer = setInterval(() => {
        // 设置回滚速度
        let ispeed = Math.floor(html.offsetHeight / 5);
        // 设置滚动条高度
        html.scrollTop = document.body.scrollTop = html.scrollTop - ispeed;
        // 清除计时器
        if (html.scrollTop == 0) {
          clearInterval(timer);
        }
      }, 16);
    },

    // 向下滚动显示图标
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 100) {
        this.isShow = true;
      } else {
        this.isShow = false;
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="less">
.backToTop {
  animation-duration: 3s;
  animation-name: fade;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.backToTop {
  position: fixed;
  bottom: 80px;
  right: 30px;
  width: 50px;
  height: 50px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.02);
  // -webkit-box-shadow: 0 1px 5px 0 rgb(0 0 0 / 6%);
  // box-shadow: 0 1px 5px 0 rgb(0 0 0 / 6%);
  border-radius: 5px;
  cursor: pointer;
  .icon-jiantou {
    display: block;
    height: 100%;
    width: 100%;
    font-size: 25px;
    font-weight: 700;
    line-height: 50px;
    color: #12a7b4;
    text-align: center;
  }
}
</style>
