import Vue from "vue";
import Router from "vue-router";
// 礼包
const giftPack = (resolve) => {
  require.ensure(["../views/index.vue"], () => {
    resolve(require("../views/index.vue"));
  });
};
// 礼包详情
const GiftPackDetail = (resolve) => {
  require.ensure(["../views/detail.vue"], () => {
    resolve(require("../views/detail.vue"));
  });
};

Vue.use(Router);

let base = `${process.env.BASE_URL}`;

export default new Router({
  mode: "history",
  base: base,
  routes: [
    {
      path: "/strategy",
      name: "Strategy",
      component: giftPack,
      meta: {
        title: "资讯",
      },
    },
    {
      path: "/strategydetail",
      name: "StrategyDetail",
      component: GiftPackDetail,
      meta: {
        title: "资讯详情",
      },
    },
  ],
});
