import Vue from "vue";
import Vuex from "vuex";
import getters from './getters'
// 引入模块
import app from './modules/app'

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    app,
  },
  getters
})

export default store
