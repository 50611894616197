<!--
 * @Description:
 * @Author: tanqifeng
 * @Date: 2019-07-15 10:33:40
 * @LastEditors: tanqifeng
 * @LastEditTime: 2019-08-23 15:44:37
 -->
<template>
  <div :class="{ hidden: hidden }" class="pagination-container">
    <!-- <el-button size="mini" :disabled="currentPage==1" @click="Oneclick">首页</el-button> -->
    <el-pagination
      :background="background"
      :current-page.sync="currentPage"
      :page-size.sync="pageSize"
      :layout="layout"
      :page-sizes="pageSizes"
      :total="total"
      v-bind="$attrs"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <!-- <el-button size="mini" :disabled="currentPage==Math.ceil(total / limit)" @click="oldclick">尾页</el-button> -->
  </div>
</template>

<script>
import { scrollTo } from "@/utils/scroll-to";

export default {
  name: "Pagination",
  props: {
    total: {
      required: true,
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    firstPage: {
      type: Number,
    },
    page: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 10,
    },
    pageSizes: {
      type: Array,
      default() {
        return [6, 10,20, 30, 50];
      },
    },
    layout: {
      type: String,
      default: "total, sizes, prev, pager, next, jumper",
    },
    background: {
      type: Boolean,
      default: false,
    },
    autoScroll: {
      type: Boolean,
      default: true,
    },
    hidden: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentPage: {
      get() {
        return this.page;
      },
      set(val) {
        this.$emit("update:page", val);
      },
    },
    pageSize: {
      get() {
        return this.limit;
      },
      set(val) {
        this.$emit("update:limit", val);
      },
    },
  },
  methods: {
    handleSizeChange(val) {
      this.currentPage = 1;
      this.$emit("pagination", { page: 1, limit: val });
      if (this.autoScroll) {
        scrollTo(0, 800);
      }
    },
    handleCurrentChange(val) {
      this.$emit("pagination", { page: val, limit: this.pageSize });
      if (this.autoScroll) {
        scrollTo(0, 800);
      }
    },
    Oneclick() {
      this.currentPage = 1;
      this.handleCurrentChange(1);
    },
    oldclick() {
      this.currentPage = Math.ceil(this.total / this.limit);
      this.handleCurrentChange(Math.ceil(this.total / this.limit));
    },
  },
};
</script>

<style lang="less">
@import "./index.less";
</style>
