<template>
  <div class="qytx__carousel-side">
    <swiper :options="swiperOption" class="qytx__swiper-side">
      <swiper-slide v-for="(item, index) in carouselList" :key="index">
        <img
          @click="toGameDetail(item.id)"
          class="qytx__swiper-img"
          v-lazy="imgBaseUrl + item.pic"
          alt="点击跳转"
        />
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>
<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { hotGameData } from "@/api/common";
export default {
  name: "CarouselSide",
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      imgBaseUrl: process.env.VUE_APP_IMG_BASE_URL, //图片根地址
      swiperOption: {
        // 所有的参数同 swiper 官方 api 参数
        direction: "horizontal",
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        autoplay: {
          delay: 60500,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        }, // 可选选项，自动滑动
      },
      carouselList: [],
    };
  },
  created() {
    this.getCarouselData();
  },
  methods: {
    async getCarouselData() {
      let param = {
        str: "",
        pageSize: "6",
      };
      let res = await hotGameData(param);
      if (res.code == 200) {
        this.$set(this, "carouselList", res.data);
      }
    },
    toGameDetail(id) {
      this.$openRouter({
        name: "game_detail.html", // 跳转地址
        query: { id: id },
      });
    },
  },
};
</script>

<style lang="less">
@import "./index.less";
</style>
