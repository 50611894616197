import Mock from 'mockjs'
import _ from 'lodash'
const Random = require('mockjs').Random

// const img = [, Random.image('40x40')]
// const videoList = ['http://img.yopoo.cn/banner_video.mp4', 'http://img.yopoo.cn/banner_video.mp4', 'http://img.yopoo.cn/banner_video.mp4']

const data = Mock.mock({
    'items|100': [{
        "poster": Random.image('600x300', Random.color(), '@csentence(3, 5)'),
        "app": '@csentence(4, 20)',
        "msg": "@cparagraph",
        "author": "@cname",
        'score': '@integer(0, 9)' + '.' + '@integer(0, 10)',
        "recommend|1-2": false,
        'is_test|1-2': true,
        'intro': Random.ctitle(8, 20),
        'url': 'http://img.yopoo.cn/banner_video.mp4',
        "id|+1": 1
    }]
})
export default [
    {
        url: '/home/index',
        type: 'get',
        response: config => {
            var info = config.query
            const items = data.items
            var [index, size, total] = [info.pageNum, info.pageSize || 10, items.length]
            var len = total / size
            var totalPages = len - parseInt(len) > 0 ? parseInt(len) + 1 : len
            var newDataList = _.chunk(data.items, size)[index - 1]
            return {
                code: 200,
                data: {
                    total: items.length,
                    lastPages: totalPages,
                    pageSize: size,
                    list: newDataList,
                    pageNum: index
                }
            }
        }
    }
]