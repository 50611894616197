<template>
  <header class="qytx__header">
    <div class="qytx__header-container">
      <a
        class="qytx__header-container-logo"
        :class="{ 'qytx__header-container-logo-mini': isCollapse }"
      >
        <img v-if="isCollapse" src="@/assets/images/logo@min.png" alt="" />
        <img
          v-else
          style="margin-left:8px"
          src="@/assets/images/logo.png"
          alt=""
        />
      </a>
      <el-menu
        class="qytx__header-container-nav"
        :default-active="currentActive"
        :collapse="isCollapse"
      >
        <el-menu-item
          v-for="(item, index) in routersList"
          :key="index"
          :index="item.route"
          class="qytx__header-container-nav-item"
          :class="{ 'qytx__header-container-nav-item-mini': isCollapse }"
          @click="navigationTo(item)"
        >
          <svg-icon :icon-class="item.icon"></svg-icon>
          <span slot="title" class="nav-item__link-title">{{ item.name }}</span>
        </el-menu-item>
      </el-menu>
    </div>
  </header>
</template>
<script>
import Cookies from "js-cookie";
export default {
  name: "LeftNav",
  props: {
    isCollapse: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      screenWidth: document.body.clientWidth,
      routersList: [
        { name: "首页", icon: "home", route: "index.html" },
        { name: "热门", icon: "hot", route: "hot.html" },
        { name: "资讯", icon: "news", route: "news.html" },
        { name: "攻略", icon: "strategy", route: "strategy.html" },
        { name: "礼包码", icon: "gift", route: "giftpack.html" },
      ],
      currentActive: "index.html",
    };
  },
  created() {
    let path = location.pathname.split("/")[1];
    if (!path.length) {
      this.$set(this, "currentActive", "index.html");
      return;
    }
    this.$set(this, "currentActive", Cookies.get("currentActive"));
  },
  mounted() {},
  watch: {},
  methods: {
    navigationTo(val) {
      Cookies.set("currentActive", val.route);
      this.$openRouter({
        name: val.route, // 跳转地址
      });
    },
  },
};
</script>
<style scoped lang="less">
@media screen and (max-width: 466px) {
  .qytx__header {
    display: none;
  }
}
.qytx__header {
  max-width: 232px;
  position: relative;
  z-index: 111;
}
.qytx__header-container {
  position: sticky;
  top: 0px;
}
.qytx__header-container-logo {
  width: 215px;
  height: 63px;
  display: inline-block;
}
.qytx__header-container-logo-mini {
  width: 63px !important;
}
.qytx__header-container-logo img {
  width: 100%;
  margin-top: 16px;
}
.qytx__header-container-nav {
  width: 100%;
  margin-top:74px;
  border-right: none;
  background: none;
}
.qytx__header-container-nav-item {
  /* padding-left: 15px; */
  width: 232px;
  color: rgb(0, 0, 0);
  height: 48px;
  margin-bottom: 15px;
  font-weight: 700;
  line-height: 48px;
  border-radius: 38px;
  display: flex;
  align-items: center;
}
.qytx__header-container-nav-item.is-active {
  color: #09a7ce !important;
  background: rgba(9, 167, 206, 0.2);
}
.qytx__header-container-nav-item svg {
  width: 26px;
  height: 29px;
  vertical-align: inherit;
}
.qytx__header-container-nav-item-mini {
  width: 50px !important;
}
.qytx__header-container-nav-item-mini /deep/.el-tooltip {
  padding: 0px 12px !important;
}

.qytx__header-container-nav-item:hover {
  background: rgba(0, 0, 0, 0.06);
}
.qytx__header-container-nav-item a {
  display: inline-block;
  width: 100%;
  border-radius: 30px;
}
.qytx__header-container-nav-item a i {
  padding-left: 15px;
  font-size: 22px;
}
.nav-item__link-title {
  /* color: rgb(0, 0, 0); */
  line-height: 26px;
  font-size: 18px;
  margin-left: 10px;
  /* font-weight: 700 !important; */
}
</style>
