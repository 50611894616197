
import request from '@/utils/request'
// 资讯数据
export function newsData(params) {
    return request({
        url: '/v1/news/list',
        method: 'get',
        params: params
    })
}


//资讯详情
export function newsDetails(params) {
    return request({
        url: '/v1/news/info',
        method: 'get',
        params: params
    })
}
//相关阅读和资讯
export function defNews(params) {
    return request({
        url: '/v1/news/getDefNew',
        method: 'get',
        params: params
    })
}
