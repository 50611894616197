import Vue from 'vue'

import _ from 'lodash'
Vue.prototype._ = _
// 全局通用样式
import '../stylesheets/main.less'
import less from 'less'
Vue.use(less)

// 引入element
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

// 引入懒加载图片
import loadingImg from "@/assets/images/loadmore.gif";
import errorImg from "@/assets/images/logo@min.png";
// 项目提示；
Vue.config.productionTip = false;

// 引入懒加载插件
import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload, {
    loading: loadingImg,
    error: errorImg,
    preLoad: 1.3,
    attempt: 1
});

// 无线滚动插件
import infiniteScroll from "vue-infinite-scroll";
Vue.use(infiniteScroll);

//注册全局meta修改
import Meta from 'vue-meta'
Vue.use(Meta)
//svgIcon
import '@/assets/icons/index'

//全局组件
import './ui-components'

// 引入Mock
import "../../mock";

import Video from 'video.js'
import 'video.js/dist/video-js.css'

Vue.prototype.$video = Video

//动画库
import animated from 'animate.css'
Vue.use(animated)

//vue 复制粘贴
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)