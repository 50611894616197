
import request from '@/utils/request'
// 攻略数据
export function strategyData(params) {
    return request({
        url: '/v1/strategy/list',
        method: 'get',
        params: params
    })
}


//攻略详情
export function strategyInfoData(params) {
    return request({
        url: '/v1/strategy/info',
        method: 'get',
        params: params
    })
}

//相关阅读和资讯
export function defStrategy(params) {
    return request({
        url: '/v1/strategy/getDefStrategy',
        method: 'get',
        params: params
    })
}